import React from 'react'
import * as styles from './index.module.less'

interface ListItemProps {
  imgSrc: string
  title: string
  desc: string
  tags: string[]
  href: string
}

const ListItem: React.FC<ListItemProps> = ({ imgSrc, title, desc, tags, href }) => {
  return (
    <div className={styles.listItemRoot} onClick={() => window.open(href, '_blank')}>
      <div className={styles.imageWrapper}>
        <img src={imgSrc} alt={title} />
      </div>
      <div className={styles.descriptionWrapper}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.desc}>{desc}</p>
        <div className={styles.tagsWrapper}>
          {tags.map((tag, idx) => (
            <div key={idx} className={styles.tag}>
              <span className={styles.tagText}>{tag}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const NewsList: React.FC<any> = ({ data }) => {
  return (
    <div className={styles.listWrapper}>
      {data?.map((item) => {
        const { img, ...other } = item
        return <ListItem key={item.title} imgSrc={img?.publicURL ?? img} {...other} />
      })}
    </div>
  )
}

export default NewsList
