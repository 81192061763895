import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import { Seo, SimpleBanner, LeftRightLayout, SubChapter, SwiperCom, Button, VideoList } from 'components/index'
import NewsList from 'components/InstituteCom/NewsList'
import ArticleRankingList from 'components/InstituteCom/ArticleRankingList'
import MoreButton from 'components/InstituteCom/MoreButton'
import * as styles from './index.module.less'
import BannerImg from 'assets/images/researchInstitute/banner.png'
import BannerImgMb from 'assets/images/researchInstitute/bannerMb.png'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface researchInstituteProps {
  [x: string]: any
}

const ResearchInstitute: React.FC<researchInstituteProps> = (props) => {
  const { data } = props
  const { researchInstituteYaml } = data
  const { part1, part2, part3, part4, part5, part6, part7 } = researchInstituteYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="神策研究院-企业数字化经营实践/行业洞察"
        description="神神策数据研究院围绕企业数字化经营领域，提供多维度行业洞察与市场分析，分享行业数字化实践案例，帮助企业拓宽行业视野，利用数据驱动加速企业数字化转型。"
        keywords="企业数字化转型,数字化经营,行业实践,行业洞察,市场研究"
        saTitle="官网 - 资源中心（行业研究与实践）-神策研究院"
      />
      <div className={styles.instituteRoot}>
        <div className={styles.bannerWrapper}>
          <SimpleBanner
            title="神策研究院"
            desc="以数字化经营为目标，预见·洞察·创新"
            img={BannerImg}
            mbImg={BannerImgMb}
          />
        </div>
        {!isMb ? (
          // PC 端
          <LeftRightLayout
            leftContent={
              <>
                <SubChapter title="机构观点">
                  <SwiperCom
                    nodes={part1.map((item) => (
                      <img
                        src={item.img?.publicURL ?? item.img}
                        onClick={() => {
                          window.open(item.href, '_blank')
                        }}
                      ></img>
                    ))}
                    indicatorType="bottom"
                    isAutoplay
                    isAnimation
                  />
                </SubChapter>
                <SubChapter
                  title="深度洞察"
                  rightNode={
                    <a
                      href="https://www.sensorsdata.cn/researchInstitute/report"
                      target={'_blank'}
                      className="text-[12px] text-[#99A9BF] leading-[17px] font-[400] cursor-pointer"
                    >
                      {'查看全部  >'}
                    </a>
                  }
                >
                  <NewsList data={part2}></NewsList>
                </SubChapter>
                {/* <SubChapter title="客户旅程编排 CJO 理念">
                  <div className="h-[450px] w-[100%]">
                    <img className="h-[100%] w-[100%]" src={part3.img?.publicURL ?? part3.img} alt="" />
                  </div>
                  <div className="mt-[30px] text-center">
                    <Button btnType="primary" useLink target="_blank" href={part3.href}>
                      观看视频资料
                    </Button>
                  </div>
                </SubChapter> */}
              </>
            }
            rightContent={
              <>
                {/* <SubChapter title="前沿新知">
                  <ArticleRankingList data={part4}></ArticleRankingList>
                </SubChapter> */}
                <SubChapter title="大咖专访">
                  <VideoList data={part6} />
                </SubChapter>
                <SubChapter title="研究院介绍">
                  <div className="text-[16px] text-[#475669] leading-[24px]">{part5.content}</div>
                </SubChapter>
                {/* <SubChapter title="关注我们">
                  <div className="w-[247px] h-[247px] bg-white shadow-default rounded-[4px] flex items-center justify-center">
                    <img src={part7.qrcode?.publicURL ?? part7.qrcode} alt="关注我们" />
                  </div>
                </SubChapter> */}
              </>
            }
          />
        ) : (
          // 移动端
          <div className="px-[2rem] pb-[3.6rem]">
            <SubChapter title="机构观点">
              <SwiperCom
                className={styles.mbSwiper}
                nodes={part1.map((item) => (
                  <img
                    src={item.img?.publicURL ?? item.img}
                    onClick={() => {
                      window.open(item.href, '_blank')
                    }}
                  ></img>
                  // <img src={item.img?.publicURL ?? item.img} className="w-[26.6rem] h-[14.4rem]"></img>
                ))}
                isAutoplay={true}
                indicatorType="bottom"
                indicatorGapType="short"
                swiperType="double"
              />
            </SubChapter>
            {/* <SubChapter title="前沿新知">
              <ArticleRankingList data={part4}></ArticleRankingList>
            </SubChapter> */}
            <SubChapter title="深度洞察">
              <>
                <NewsList data={part2}></NewsList>
                <div className="flex items-center justify-center mt-[3rem]">
                  <MoreButton
                    openText="查看更多"
                    onClick={() => (window.location.href = 'https://www.sensorsdata.cn/researchInstitute/report')}
                  />
                </div>
              </>
            </SubChapter>
            <SubChapter title="大咖专访">
              <VideoList data={part6} />
            </SubChapter>
            {/* <SubChapter title="客户旅程编排 CJO 理念">
              <div className="h-[17.3rem] w-[100%]">
                <img
                  className="h-[100%] w-[100%]"
                  src={part3.img?.publicURL ?? part3.img}
                  alt="客户旅程编排 CJO 理念"
                />
              </div>
              <div className="mt-[3rem] text-center mb-[3rem]">
                <Button btnType="primary" useLink target="_blank" href={part3.href}>
                  观看视频资料
                </Button>
              </div>
            </SubChapter> */}
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    researchInstituteYaml {
      part1 {
        img {
          publicURL
        }
        href
      }
      part2 {
        img
        title
        desc
        tags
        href
      }
      part3 {
        img {
          publicURL
        }
        href
      }
      part4 {
        title
        href
      }
      part5 {
        content
      }
      part6 {
        img {
          publicURL
        }
        href
        title
      }
      part7 {
        qrcode {
          publicURL
        }
      }
    }
  }
`

export default ResearchInstitute
